import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AnchorLink from "react-anchor-link-smooth-scroll"
import {
  LazyLoadImage as img,
  LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

//reveal animations
import { Fade, Bounce } from "react-reveal"

//faq accordion
import FaqAccordion from "@components/faqAccordion/faqAccordion"

//swiper
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"

//hero particles
import Particles from "react-particles-js"

//pricing accordion
import PricingTable from "@components/pricingTable/pricingTable"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    const params = {
      slidesPerView: 1,
      spaceBetween: 50,
      speed: 1000,
      navigation: {
        nextEl: "#invisible-next",
        prevEl: "#invisible-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" description={siteDescription} />
        <div className="hero-section">
          <LazyLoadComponent>
            <Particles
              params={{
                fps_limit: 60,
                interactivity: {
                  detect_on: "canvas",
                  events: {
                    onclick: { enable: true, mode: "repulse" },
                    onhover: {
                      enable: true,
                      mode: "bubble",
                      parallax: { enable: false, force: 60, smooth: 10 },
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 400,
                      duration: 2,
                      opacity: 0.8,
                      size: 30,
                      speed: 3,
                    },
                    grab: { distance: 400 },
                    push: { particles_nb: 4 },
                    remove: { particles_nb: 2 },
                    repulse: { distance: 200, duration: 0.4 },
                  },
                },
                particles: {
                  color: { value: "random" },
                  line_linked: { enable: false },
                  move: {
                    attract: { enable: false, rotateX: 600, rotateY: 1200 },
                    bounce: false,
                    direction: "none",
                    enable: true,
                    out_mode: "out",
                    random: false,
                    speed: 3,
                    straight: false,
                  },
                  number: {
                    density: { enable: true, value_area: 1000 },
                    value: 15,
                  },
                  opacity: {
                    anim: {
                      enable: true,
                      opacity_min: 0.5,
                      speed: 1,
                      sync: false,
                    },
                    random: false,
                    value: 1,
                  },
                  shape: {
                    character: [
                      {
                        fill: true,
                        font: "FontAwesome",
                        style: "",
                        value: [
                          "\uf09a",
                          "\uf099",
                          "\uf16d",
                          "\uf0e1",
                          "\uf1e0",
                          "\uf087",
                          "\uf08a",
                        ],
                        weight: "400",
                      },
                      // {
                      //   fill: true,
                      //   font: "FontAwesome",
                      //   style: "",
                      //   value: [
                      //     "\uf06a",
                      //     "\uf7ba",
                      //     "\uf3ed",
                      //     "\uf55f",
                      //     "\uf013",
                      //   ],
                      //   weight: "900",
                      // },
                    ],
                    // image: {
                    //   height: 100,
                    //   replace_color: true,
                    //   src: "images/github.svg",
                    //   width: 100,
                    // },
                    polygon: { nb_sides: 5 },
                    stroke: { color: "#ffffff", width: 1 },
                    type: "char",
                  },
                  size: {
                    anim: {
                      enable: true,
                      size_min: 10,
                      speed: 10,
                      sync: false,
                    },
                    random: false,
                    value: 16,
                  },
                },
                retina_detect: true,
              }}
            />
          </LazyLoadComponent>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 text-center">
                <h1 className="display-4 text-white">
                  Let Us Build Your Brand Influence With DFY Social Media
                  Content
                </h1>
              </div>
              <div className="col-md-8 text-center">
                <p className="lead py-4">
                  RollSocial creates fresh, hand-written, well researched
                  content for your social media pages for the crazy low price of
                  $99/mo — helping you build and maintain relationships at scale
                  through social media. 
                  <span className="font-weight-bold">100% Done-for-you.</span>
                </p>
                <div className="text-center">
                  <Link to="/" className="btn btn-primary btn-lg shadow-dark">
                    <i className="fa fa-pencil-square-o pr-2"></i>Access
                    Training Now (for FREE)
                  </Link>
                </div>
                <div className="text-center py-4">
                  <Link to="/" className="text-white dotted-link">
                    Or Try RollSocial <strong>FREE</strong> for 7 Days.
                  </Link>
                </div>
                <div className="stars">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                </div>
                <AnchorLink
                  offset="100"
                  className="scroll-down"
                  href="#process"
                >
                  <span></span>
                </AnchorLink>
              </div>
            </div>
          </div>
          <svg
            className="wave"
            width="100%"
            height="268"
            viewBox="0 0 1440 268"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 77.8064H40.32C80.64 77.8064 161.28 77.8064 239.04 66.2796C319.68 54.7527 400.32 34.5806 478.08 17.2903C561.6 -3.34603e-06 642.24 -8.64516 720 11.5269C800.64 34.5806 881.28 86.4516 961.92 86.4516C1042.56 86.4516 1123.2 31.6989 1200.96 31.6989C1278.72 31.6989 1362.24 86.4516 1399.68 112.387L1440 141.204V270.882H1399.68C1359.36 270.882 1278.72 270.882 1200.96 270.882C1123.2 270.882 1039.68 270.882 961.92 270.882C881.28 270.882 800.64 270.882 722.88 270.882C645.12 270.882 561.6 270.882 483.84 270.882C406.08 270.882 322.56 270.882 244.8 270.882C167.04 270.882 83.52 270.882 46.08 270.882H0V77.8064Z"
                fill="#d9d7fa"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 97.9785H40.32C80.64 97.9785 161.28 97.9785 239.04 89.3333C319.68 80.6882 400.32 60.5161 478.08 46.1075C558.72 31.6989 639.36 23.0538 717.12 40.3441C797.76 60.5161 878.4 106.624 956.16 106.624C1033.92 106.624 1120.32 57.6344 1200.96 57.6344C1281.6 57.6344 1362.24 106.624 1399.68 129.677L1440 155.613V270.882H1399.68C1359.36 270.882 1278.72 270.882 1200.96 270.882C1123.2 270.882 1039.68 270.882 961.92 270.882C884.16 270.882 800.64 270.882 722.88 270.882C645.12 270.882 561.6 270.882 483.84 270.882C406.08 270.882 322.56 270.882 244.8 270.882C167.04 270.882 83.52 270.882 46.08 270.882H0V97.9785Z"
                fill="#ECF0F3"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="1440" height="268" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div id="process" className="bg-light pb-100 steps-section">
          <div className="container">
            <div className="row justify-content-center align-items-center pb-5">
              <div className="col-md-12 text-center">
                <Fade up>
                  <h2 className="display-5">
                    We'll Handle Your Social Media,
                    <br />
                    You Handle Running Your Business
                  </h2>
                  <p className="lead pt-5">
                    We know it can be difficult to constantly create daily
                    content that keeps your business visible on social media.
                    <br />
                    We live and breathe social media so you don't have to.
                  </p>
                </Fade>
              </div>
            </div>
            <div className="row justify-content-center py-5">
              <div className="col-md-6 col-lg-4 mb-4">
                <Fade left duration={1000}>
                  <div className="p-4 h-100 step-box rounded">
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        effect="blur"
                        className="d-flex"
                        src={require("@images/step1.svg")}
                        width="100px"
                      />
                      <span className="font-weight-bold text-muted text-uppercase step-num">
                        Step 1
                      </span>
                    </div>
                    <h4 className="py-3">Sign up to get started.</h4>
                    <p>
                      Pick your plan and click the “Get Started Now” button
                      below to begin. You’ll then complete your order and create
                      your account by entering payment details. You’ll get an
                      email from us right away to help you begin.
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <Fade left duration={1000} delay={500}>
                  <div className="p-4 h-100 step-box rounded">
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        effect="blur"
                        src={require("@images/step2.svg")}
                        width="100px"
                      />
                      <span className="font-weight-bold text-muted text-uppercase step-num">
                        Step 2
                      </span>
                    </div>
                    <h4 className="py-3">Tell us about your business.</h4>
                    <p>
                      You'll tell us about your business, and will schedule a
                      strategy session with our team. On the call we'll dive in
                      to your brand and the specific needs of your business.
                      After providing account access we'll begin working!
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <Fade left duration={1000} delay={1000}>
                  <div className="p-4 h-100 step-box rounded">
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        effect="blur"
                        src={require("@images/step3.svg")}
                        width="100px"
                      />
                      <span className="font-weight-bold text-muted text-uppercase step-num">
                        Step 3
                      </span>
                    </div>
                    <h4 className="py-3">Watch the posting begin.</h4>
                    <p>
                      Get excited, because you just saved yourself countless
                      hours of work. We'll prepare 3-5 initial posts for review,
                      and you'll start seeing posts come through within 2
                      business days of giving our team access to your accounts.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="text-center pt-5">
              <Fade up>
                <Link to="/" className="btn btn-primary">
                  Check Out the F.I.R.E. Formula for Social Sales
                </Link>
              </Fade>
            </div>
          </div>
        </div>

        <div className="pt-100 is-brand-invisible">
          <div className="container">
            <div className="row text-center justify-content-center py-5">
              <div className="col-md-8 mb-4">
                <Fade up>
                  <h2 className="display-5">
                    Is your brand invisible on social media channels?
                  </h2>
                  <p className="lead py-4">
                    Wondering why you should invest in social media content?
                    Consistent social content is the missing key to brand
                    awareness at scale with your target market.
                  </p>
                </Fade>
              </div>
            </div>
            <LazyLoadComponent>
              <Fade up>
                <div className="why-swiper-wrapper">
                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <LazyLoadComponent>
                        <Swiper {...params}>
                          <div className="rounded-lg p-5 brand-invisible h-auto">
                            <div className="row text-left justify-content-center align-items-center">
                              <div className="col-md-3 text-center mb-4">
                                <img
                                  alt=""
                                  effect="blur"
                                  className="img-fluid"
                                  src={require("@images/not-consistent.svg")}
                                  width="150px"
                                />
                              </div>
                              <div className="col-md-9 mb-4">
                                <h3 className="text-white pb-4">
                                  If you're not consistent with content, you're
                                  invisible.
                                </h3>
                                <p className="text-white">
                                  In today's noisy world, your brand needs to
                                  capture and maintain the attention of your
                                  dream clients. If you're not active on social
                                  media, you business will appear stagnant.
                                  Unfortunately, one post per week won't do the
                                  trick. You need to post consistently week in
                                  and week out to become an "active" business,
                                  and maintain the attention of your market.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="rounded-lg p-5 brand-invisible h-auto">
                            <div className="row text-left justify-content-center align-items-center">
                              <div className="col-md-3 text-center mb-4">
                                <img
                                  alt=""
                                  effect="blur"
                                  className="img-fluid"
                                  src={require("@images/hidden-cost.svg")}
                                  width="150px"
                                />
                              </div>
                              <div className="col-md-9 mb-4">
                                <h3 className="text-white pb-4">
                                  The hidden cost of writing your own posts.
                                </h3>
                                <p className="text-white">
                                  Have you ever recorded how much time you
                                  actually spend on social media each week? If
                                  you're not creating content, you're invisible
                                  and missing out on sales. If you are creating
                                  content, you're not focusing on running your
                                  actual business. Social content is a
                                  necessity, but you shouldn't do it yourself.
                                  Writing your own posts can take hundreds of
                                  hours, and that's time you should be spending
                                  managing other areas of your business.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="rounded-lg p-5 brand-invisible h-auto">
                            <div className="row text-left justify-content-center align-items-center">
                              <div className="col-md-3 text-center mb-4">
                                <img
                                  alt=""
                                  effect="blur"
                                  className="img-fluid"
                                  src={require("@images/not-active.svg")}
                                  width="150px"
                                />
                              </div>
                              <div className="col-md-9 mb-4">
                                <h3 className="text-white pb-4">
                                  If you're not active on social, you are losing
                                  sales.
                                </h3>
                                <p className="text-white">
                                  Your clients are looking for your on their
                                  preferred social network... but are you there?
                                  If you are, are you active and posting
                                  consistently? If the answer is NO... you're
                                  losing money. Attention is the new currency of
                                  business, and maintaining an active social
                                  media profile isn't just an option... it's
                                  becoming a requirement. Miss this trend, and
                                  you may find yourself losing out to your more
                                  social savvy competitors.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Swiper>
                      </LazyLoadComponent>
                    </div>
                    <div className="why-swiper-nav">
                      <button id="invisible-prev">
                        <i className="fa fa-chevron-left"></i>
                      </button>
                      <button id="invisible-next">
                        <i className="fa fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </Fade>
            </LazyLoadComponent>

            <div className="text-center pt-5">
              <Fade up>
                <AnchorLink
                  href="#pricing"
                  offset="100"
                  className="btn btn-primary"
                >
                  View Pricing
                </AnchorLink>
              </Fade>
            </div>
          </div>
        </div>

        <div className="fraction-of-cost">
          <svg
            className="wave"
            width="100%"
            height="255px"
            viewBox="0 0 1440 255"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1440 -1.3113e-06L1400 38.0206C1360 75.3856 1280 150.771 1200 169.781C1120 188.792 1040 150.771 960 156.015C880 160.604 800 207.802 720 231.401C640 255 560 255 480 245.167C400 235.99 320 216.979 240 216.979C160 216.979 80 235.99 40 245.167L0 255V-1.3113e-06H40C80 -1.3113e-06 160 -1.3113e-06 240 -1.3113e-06C320 -1.3113e-06 400 -1.3113e-06 480 -1.3113e-06C560 -1.3113e-06 640 -1.3113e-06 720 -1.3113e-06C800 -1.3113e-06 880 -1.3113e-06 960 -1.3113e-06C1040 -1.3113e-06 1120 -1.3113e-06 1200 -1.3113e-06C1280 -1.3113e-06 1360 -1.3113e-06 1400 -1.3113e-06H1440Z"
              fill="white"
            />
          </svg>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center mb-4">
                <Fade up>
                  <h2 className="text-pink display-5">
                    A Full Time Social Media Manager At A Fraction Of The Cost
                  </h2>
                  <p className="lead py-4 text-white">
                    Our diligent internal review and client approval process
                    helps us produce 10x the results of the average internal
                    social media hire.
                  </p>
                </Fade>
              </div>
            </div>
            <div className="text-center pb-5">
              <Fade up>
                <Link to="/" className="btn btn-primary shadow-dark">
                  Try RollSocial Free
                </Link>
              </Fade>
            </div>
          </div>
          <svg
            className="wave"
            width="100%"
            height="343px"
            viewBox="0 0 1440 343"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 0L60 31.7429C120 63.4859 240 126.972 360 146.37C480 164.887 600 140.198 720 133.144C840 126.972 960 140.198 1080 171.941C1200 203.684 1320 253.943 1380 279.514L1440 305.085V343H1380C1320 343 1200 343 1080 343C960 343 840 343 720 343C600 343 480 343 360 343C240 343 120 343 60 343H0V0Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="container fraction-of-cost-cols">
          <div className="row justify-content-center">
            <div className="col-md-4 text-center mb-4">
              <Bounce>
                <div className="p-5 rounded shadow h-100 bg-white">
                  <img
                    alt=""
                    effect="blur"
                    src={require("@images/dailysocial.svg")}
                    width="100px"
                  />
                  <h4 className="my-4">Daily Social Posts</h4>
                  <p>
                    We log in to your account and create content in line with
                    your brand, day-in and day-out. We are meticulous about
                    brand consistency, and our goal is to add value to your
                    audience and keep your business both relevant and visible.
                  </p>
                </div>
              </Bounce>
            </div>
            <div className="col-md-4 text-center mb-4">
              <Bounce delay={500}>
                <div className="p-5 rounded shadow h-100 bg-white">
                  <img
                    alt=""
                    effect="blur"
                    src={require("@images/approvalportal.svg")}
                    width="100px"
                  />
                  <h4 className="my-4">Approval Portal</h4>
                  <p>
                    We will give you access to a specific approval portal where
                    you can view your scheduled posts, provide feedback, request
                    revisions, and approve your content. Be super involved, or
                    set it and forget it — the choice is yours.
                  </p>
                </div>
              </Bounce>
            </div>
            <div className="col-md-4 text-center mb-4">
              <Bounce delay={1000}>
                <div className="p-5 rounded shadow h-100 bg-white">
                  <img
                    alt=""
                    effect="blur"
                    src={require("@images/authoritycontent.svg")}
                    width="100px"
                  />
                  <h4 className="my-4">Authority Content</h4>
                  <p>
                    The real secret to long-term sales success is content
                    marketing that adds value to your network. Leverage our
                    hand-written content to build true brand authority and
                    become the top-of-mind option in the minds of your
                    prospects.
                  </p>
                </div>
              </Bounce>
            </div>
          </div>
        </div>

        <div id="pricing" className="pricing-section pt-100">
          <div className="container">
            <div className="text-center pt-5">
              <Fade up>
                <h2 className="display-5 text-purple">Affordable Pricing</h2>
                <p className="lead py-4">
                  Month-to-month. No long-term contracts. Cancel anytime.
                </p>
              </Fade>
            </div>
            <Fade up>
              <>
                <PricingTable />
              </>
            </Fade>
            <Fade up>
              <p className="text-center">
                *There's no catch. Try RollSocial FREE for 7 days, and cancel
                any time.
                <br />
                **FREE Buffer account required on all plans.
              </p>
            </Fade>
          </div>
        </div>

        <div className="cta-section text-center">
          <svg
            className="wave"
            width="100%"
            height="100%"
            viewBox="0 0 1440 179"
            fill="none"
            preserveAspectRatio="xMaxYMax meet"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 78.9628L60 83.2147C120 87.4665 240 95.9702 360 122.696C480 148.815 600 192.548 720 174.933C840 157.318 960 78.9628 1080 65.5999C1200 52.2369 1320 105.081 1380 131.2L1440 157.318V3.73591e-07H1380C1320 3.73591e-07 1200 3.73591e-07 1080 3.73591e-07C960 3.73591e-07 840 3.73591e-07 720 3.73591e-07C600 3.73591e-07 480 3.73591e-07 360 3.73591e-07C240 3.73591e-07 120 3.73591e-07 60 3.73591e-07H0V78.9628Z"
              fill="white"
            />
          </svg>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <Fade up>
                  <h2 className="display-4 text-white">
                    Tap Into The Power Of Social
                  </h2>
                  <p className="lead py-4">
                    Watch our short training video to discover the method we use
                    to craft amazing content for your brand.
                  </p>
                </Fade>
                <Bounce>
                  <Link to="/" className="btn btn-secondary shadow-dark">
                    <i className="fa fa-play-circle-o pr-2"></i>Free Video
                    Training
                  </Link>
                  <div className="py-4">
                    <Link to="/" className="text-white dotted-link">
                      Or Check Out Our F.I.R.E. Formula Methodology
                    </Link>
                  </div>
                </Bounce>
              </div>
            </div>
          </div>
          <svg
            className="wave"
            width="100%"
            height="100%"
            viewBox="0 0 1440 259"
            fill="none"
            preserveAspectRatio="xMaxYMax meet"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 0H60C120 0 240 0 360 21C480 43 600 86 720 108C840 129 960 129 1080 144C1200 158 1320 187 1380 201L1440 216V259H1380C1320 259 1200 259 1080 259C960 259 840 259 720 259C600 259 480 259 360 259C240 259 120 259 60 259H0V0Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="faq-section pb-100">
          <div className="skewed-bg skewed--lightbg"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h3 className="text-center pb-5">Frequently Asked Questions</h3>
                <LazyLoadComponent>
                  <FaqAccordion />
                </LazyLoadComponent>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <Fade duration={1000}>
                  <div className="text-center rounded-lg shadow-dark px-3 py-5 still-have-questions text-white">
                    <h2 className="text-white">Still Have Questions?</h2>
                    <p className="py-2">
                      We have answers. Check out our robust FAQ section or try
                      RollSocial risk free for 14 days!
                    </p>
                    <Link to="/" className="btn btn-secondary shadow-dark">
                      <i className="fa fa-play-circle-o pr-2"></i>Watch the Free
                      Training
                    </Link>
                    <div className="py-4">
                      <Link to="/" className="text-white small dotted-link">
                        Or Start Your Free Trial To Book A Call With Our Team —
                        And We'll Answer Questions During Your Strategy Session
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
