import React, { useState } from "react"
import { Accordion, Card } from "react-bootstrap"

import goldPricing from "./gold.json"
import silverPricing from "./silver.json"
import basePricing from "./base.json"

import "./pricing-table.scss"

const PricingTable = () => {
  const [showYearly, setShowYearly] = useState(false)
  return (
    <>
      <div className="pricing-toggle text-center">
        <input
          onChange={() => setShowYearly(false)}
          type="radio"
          id="monthly_payment"
          name="payment_subsc"
          className={`${!showYearly ? "checked" : ""}`}
        />
        <label htmlFor="monthly_payment">Monthly</label>

        <input
          onChange={() => setShowYearly(true)}
          type="radio"
          id="yearly_payment"
          name="payment_subsc"
          className={`${showYearly ? "checked" : ""}`}
        />
        <label htmlFor="yearly_payment">Yearly</label>
      </div>

      {/*<button onClick={() => setShowYearly(!showYearly)}>Pay Yearly</button>*/}

      <div className="row justify-content-center text-center py-5">
        <div className="col-md-4 mb-4">
          <div className="bg-light pricing-table h-100">
            <div className="pricing-heading">
              <div className="price">
                <h6 className="prev-price text-white">$800/mo</h6>
                <h3 className="price-amount text-white">
                  {!showYearly ? <>497</> : <>456</>}
                </h3>
              </div>
              <div className="d-block mb-2">
                <span className="pricing-note rounded py-1 px-2">
                  {!showYearly ? <>Limited Time</> : <>Save $497</>}
                </span>
              </div>
              <span className="text-white small d-block">
                <strong>+$150</strong> one-time onboarding fee
              </span>
            </div>
            <div className="rounded price-label gold shadow">
              <h5 className="text-white text-uppercase m-0 label">Gold</h5>
              <span className="text-white small label-for">
                for INFLUENCERS
              </span>
            </div>
            <div className="px-3">
              <Accordion
                defaultActiveKey="0"
                className="pricing-table-accordion"
              >
                {goldPricing.map((feature, i) =>
                  feature.desc !== undefined ? (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle eventKey={i}>
                          <h5
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: feature.name,
                            }}
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body>
                          <p>{feature.desc}</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ) : (
                    <span className="feature-filler">{feature.name}</span>
                  )
                )}
              </Accordion>
            </div>
            <div className="p-3">
              <button
                className="btn btn-primary btn-block mt-4 mb-2"
                type="button"
              >
                Try Free
              </button>
              <p className="small">
                *7 Day FREE Trial Included.
                <br />
                Satisfaction Guaranteed.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="bg-light pricing-table h-100">
            <div className="pricing-heading">
              <div className="price">
                <h6 className="prev-price text-white">$500/mo</h6>
                <h3 className="price-amount text-white">
                  {!showYearly ? <>297</> : <>273</>}
                </h3>
              </div>
              <div className="d-block mb-2">
                <span className="pricing-note rounded py-1 px-2">
                  {!showYearly ? <>Limited Time</> : <>Save $297</>}
                </span>
              </div>
              <span className="text-white small d-block">
                <strong>+$250</strong> one-time onboarding fee
              </span>
            </div>
            <div className="rounded price-label silver shadow">
              <h5 className="text-white text-uppercase m-0 label">Silver</h5>
              <span className="text-white small label-for">
                for ENTREPRENEURS
              </span>
            </div>
            <div className="px-3">
              <Accordion
                defaultActiveKey="0"
                className="pricing-table-accordion"
              >
                {silverPricing.map((feature, i) =>
                  feature.desc !== undefined ? (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle eventKey={i}>
                          <h5
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: feature.name,
                            }}
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body>
                          <p>{feature.desc}</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ) : (
                    <span className="feature-filler">{feature.name}</span>
                  )
                )}
              </Accordion>
            </div>
            <div className="p-3">
              <button
                className="btn btn-primary btn-block mt-4 mb-2"
                type="button"
              >
                Try Free
              </button>
              <p className="small">
                *7 Day FREE Trial Included.
                <br />
                Satisfaction Guaranteed.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4">
          <div className="bg-light pricing-table h-100">
            <div className="pricing-heading">
              <div className="price">
                <h6 className="prev-price text-white">$200/mo</h6>
                <h3 className="price-amount text-white">
                  {!showYearly ? <>99</> : <>95</>}
                </h3>
              </div>
              <div className="d-block mb-2">
                <span className="pricing-note rounded py-1 px-2">
                  {!showYearly ? <>Limited Time</> : <>Save $48</>}
                </span>
              </div>
              <span className="text-white small d-block">
                <strong>+$350</strong> one-time onboarding fee
              </span>
            </div>
            <div className="rounded price-label bg-dark shadow">
              <h5 className="text-white text-uppercase m-0 label">Base</h5>
              <span className="text-white small label-for">for STARTUPS</span>
            </div>
            <div className="px-3">
              <Accordion
                defaultActiveKey="0"
                className="pricing-table-accordion"
              >
                {basePricing.map((feature, i) =>
                  feature.desc !== undefined ? (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle eventKey={i}>
                          <h5
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: feature.name,
                            }}
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body>
                          <p>{feature.desc}</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ) : (
                    <span className="feature-filler">{feature.name}</span>
                  )
                )}
              </Accordion>
            </div>
            <div className="p-3">
              <button
                className="btn btn-primary btn-block mt-4 mb-2"
                type="button"
              >
                Try Free
              </button>
              <p className="small">
                *7 Day FREE Trial Included.
                <br />
                Satisfaction Guaranteed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PricingTable
